import React from 'react'
import { Container } from 'components/common'
import { Reoverlay } from 'reoverlay'
import helpModal from 'components/modals/helpModal'
import privacyModal from 'components/modals/privacyModal'

import { usePartnerData } from 'hooks/UsePartnerData'

import { Flex, Links } from './styles'

const helpClick = () => {
  Reoverlay.showModal(helpModal, {})
}
const privacyClick = () => {
  Reoverlay.showModal(privacyModal, {})
}

export const Footer = () => {
  const { legalName } = usePartnerData()

  return (
    <footer className="site-footer">
      <Flex as={Container}>
        <p>
          Copyright © {new Date().getFullYear()} {legalName}. | All rights reserved. | The {legalName} is an equal
          opportunity affirmative action institution.
        </p>
        {/* <Details>
        <div className="logo-footer">School Name</div>
        {legalName}
        <br />
        {address.address1}
        <br />
        {address.address2}
        <br />
        {address.city}, {address.region} {address.zipCode}
        <br />
        Phone: {contact.phone}
      </Details> */}
        <Links>
          <div className="footer-btns">
            <button type="button" className="button" to="/help" onClick={helpClick}>
              Help
            </button>
            <button className="button" type="button" to="/privacy" onClick={privacyClick}>
              Privacy
            </button>
          </div>
        </Links>
      </Flex>
    </footer>
  )
}
