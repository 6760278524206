import React from 'react'
import { ModalWrapper, Reoverlay } from 'reoverlay'
import { usePartnerData } from 'hooks/UsePartnerData'

const InquireModal = () => {
  const closeModal = () => {
    Reoverlay.hideAll()
  }
  const { contact } = usePartnerData()

  return (
    <ModalWrapper
      contentContainerClassName="modalContent"
      wrapperClassName="modal_help_wrapper"
      onClose={() => Reoverlay.hideModal()}
      animation="slideUp"
    >
      <div className="help">
        <div className="head-content">
          <h2>Help</h2>
          <p>
            If you require assistance in using this site, please contact the Help Center at{' '}
            <a href={`mailto:${contact[0].email1}`}>{contact[0].email1}.</a>
          </p>
        </div>
      </div>

      <button className="closeModal" type="button" onClick={closeModal}>
        Close modal
      </button>
    </ModalWrapper>
  )
}
export default InquireModal
