import React from 'react'
import { usePartnerData } from 'hooks/UsePartnerData'

import Container from 'components/common/Container'

const Head = () => {
  const { legalName } = usePartnerData()
  return (
    <header className="site-header">
      <Container type="wrapper" constraints="centered">
        <div className="logo">{legalName}</div>
      </Container>
    </header>
  )
}

export default Head
